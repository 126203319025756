import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../utility/api";

export const physicianfetchAll = createAsyncThunk("physicianfetchAll", async (userEmail) => {
  try {
    if (userEmail == null) {
      return {
        data: [],
        isUserPermission: false,
        isUserExpAuthPermission: false,
      };
    }
    const response = await api.get(`/api/PhysicianPortal/PhysicianPatientData/${userEmail}`);

    return {
      data: response.data.data,
      isUserPermission: response.data.permission.isUserPermission,
      isUserExpAuthPermission: response.data.permission.isUserExpAuthPermission,
    };
  } catch (error) {
    console.error("Error fetching patient data:", error);
    throw error;
  }
});

export const getAddressInfo = createAsyncThunk("getAddressInfo", async () => {
  try {

    const response = await api.get(`/api/PhysicianPortal/GetAddressMasterInfo`);
    const decodedData = atob(response.data);

    const jsonData = JSON.parse(decodedData);

    return jsonData.Data
  } catch (error) {
    console.error("Error fetching patient data:", error);
    throw error;
  }
});

export const getAllZipcode = createAsyncThunk("getAllZipcode", async () => {
  try {

    const response = await api.get(`/api/PhysicianPortal/getAllZipcode`);

    return response.data
  } catch (error) {
    console.error("Error fetching patient data:", error);
    throw error;
  }
});

export const toggleModal = createAsyncThunk(
  "toggleModal",
  async ({ isOpen, id, rowData }) => {
    return {
      isOpen: isOpen,
      id: id,
      rowData: rowData,
    };
  }
);

export const documentviewToggle = createAsyncThunk(
  "documentviewToggle",
  async ({ isOpen, rowData }) => {
    return {
      isOpen: isOpen,
      rowData: rowData
    };
  }
);



export const referralSend = createAsyncThunk(
  "referralSend",
  async ({ name, lname, date, document_path, mrn, email, referral_date, address_Type_Id, zipcode_Id, state_Id, city_Id, county_Id, address_1 }) => {
    try {
      var newData = {
        mrn: mrn,
        first_name: name,
        last_name: lname,
        referral_date: referral_date,
        date_of_birth: date,
        document_path: document_path,
        email: email,
        address_Type_Id: address_Type_Id,
        city_Id: city_Id,
        state_Id: state_Id,
        zipcode_Id: zipcode_Id,
        county_Id: county_Id,
        address_1: address_1
      };

      const response = await api.post(`/api/PhysicianPortal/AddPhysician`, newData);

      return {
        mrn: mrn,
        first_name: name,
        last_name: lname,
        referral_date: referral_date,
        date_of_birth: date,
        document_path: document_path,
        email: email,
        address_Type_Id: address_Type_Id,
        city_Id: city_Id,
        state_Id: state_Id,
        zipcode_Id: zipcode_Id,
        county_Id: county_Id,
        address_1: address_1
      };
    } catch (error) {
      console.error("Error fetching patient data:", error);
      throw error;
    }
  }
);

export const editDocument = createAsyncThunk(
  "editDocument",
  async ({ formData, email, callback }, thunkApi) => {
    try {
      const response = await api.post(`/api/PhysicianPortal/UpdatePhysicianReferral`, formData);
      if (response?.data?.success) {
        thunkApi.dispatch(physicianfetchAll(email));
      }
      if (callback) {
        callback(response);
      }
    } catch (error) {
      console.error("Error fetching patient data:", error);
      throw error;
    }
  }
);


export const DeleteModal = createAsyncThunk(
  "DeleteModal",
  async ({ email, type, document_path, mrn, date_of_birth, referral_date, last_name, first_name, referral_id }) => {
    try {
      var newData = {
        referral_id: referral_id,
        first_name: first_name,
        last_name: last_name,
        referral_date: referral_date,
        date_of_birth: date_of_birth,
        mrn: mrn,
        document_path: document_path,
        type: type,
        email: email
      };

      const response = await api.post(`/api/PhysicianPortal/DeletePhysicianReferral`, newData);

      return {
        referral_id: referral_id,
        first_name: first_name,
        last_name: last_name,
        referral_date: referral_date,
        date_of_birth: date_of_birth,
        mrn: mrn,
        document_path: document_path,
        type: type,
        email: email
      };
    } catch (error) {
      console.error("Error fetching patient data:", error);
      throw error;
    }
  }
);


export const uploadDocument = createAsyncThunk(
  "uploadDocument",
  async ({ filename, email_address, document_description, document_type, category, mrn }) => {
    try {
      var newData = {
        mrn: mrn,
        filename: filename,
        email_address: email_address,
        document_description: document_description,
        document_type: document_type,
        category: category,

      };

      const response = await api.post(`/api/PhysicianPortal/AddPatientDocumentForNewIntakeReferral`, newData);

      return {
        mrn: mrn,
        filename: filename,
        email_address: email_address,
        document_description: document_description,
        document_type: document_type,
        category: category,
      };
    } catch (error) {
      console.error("Error fetching patient data:", error);
      throw error;
    }
  }
);

export const deleteToggle = createAsyncThunk(
  "deleteToggle",
  async ({ isOpen, rowData }) => {
    return {
      isdeleteOpen: isOpen,
      rowData: rowData
    };
  }
);


const physicianSlice = createSlice({
  name: "physician",
  initialState: {
    loading: false,
    modalLoading: false,
    item: [],
    isOpen: false,
    rowData: null,
    isUserExpAuthPermission: false,
    address: [],
    isdeleteOpen: false,
    documentItem: [],
    isDocumentOpen: false,
    isAddDoucmentOpen: false,
    documentData: [],
    zipItem: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(physicianfetchAll.fulfilled, (state, action) => {

      state.isUserExpAuthPermission = action.payload.isUserExpAuthPermission;
      const newData = action.payload.data?.map((val) => {
        return {
          ...val,
          category: val.category === null ? "" : val.category,
        }
      });
      state.item = newData;
      state.loading = false;
    });
    builder.addCase(getAddressInfo.fulfilled, (state, action) => {
      state.address = action.payload;
      state.loading = false;
    });
    builder.addCase(getAllZipcode.fulfilled, (state, action) => {
      state.zipItem = action.payload;

    });
    builder.addCase(physicianfetchAll.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(physicianfetchAll.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(toggleModal.fulfilled, (state, action) => {
      state.isOpen = action.payload.isOpen;
    })
    builder.addCase(referralSend.pending, (state, action) => {
      state.isOpen = true;
      state.modalLoading = true;
    });
    builder.addCase(referralSend.fulfilled, (state, action) => {
      state.isOpen = false;
      state.modalLoading = false;
    });

    builder.addCase(DeleteModal.pending, (state, action) => {
      state.isAddDoucmentOpen = true;
      state.modalLoading = true;
    });
    builder.addCase(DeleteModal.fulfilled, (state, action) => {

      state.isAddDoucmentOpen = false;
      state.modalLoading = false;
      state.item = state?.item?.filter(item => item.referral_id
        !== action.payload.referral_id
      );

    });

    builder.addCase(editDocument.pending, (state) => {
      state.isAddDoucmentOpen = true;
      state.modalLoading = true;
    });
    builder.addCase(editDocument.fulfilled, (state, action) => {

      state.isAddDoucmentOpen = false;
      state.modalLoading = false;

      // const index = state.item.findIndex(item => item.referral_id === action.payload.referral_id);

      // if (index !== -1) {
      //   state.item[index] = {
      //     ...state.item[index],
      //     ...action.payload,
      //     date_of_birth: action.payload.date_of_birth ? new Date(action.payload.date_of_birth) : null,
      //     referral_date: action.payload.referral_date ? new Date(action.payload.referral_date) : null,
      //     nursing_visite_date: action.payload.nursing_visite_date ? new Date(action.payload.nursing_visite_date) : null,
      //   };
      // }
    });

    builder.addCase(documentviewToggle.fulfilled, (state, action) => {

      state.isDocumentOpen = action.payload.isOpen;
      state.documentData = action.payload.rowData;
    })

    builder.addCase(uploadDocument.pending, (state, action) => {
      state.isAddDoucmentOpen = true;
      state.modalLoading = true;
    });
    builder.addCase(uploadDocument.fulfilled, (state, action) => {
      state.isAddDoucmentOpen = false;
      state.modalLoading = false;
    });


    builder.addCase(deleteToggle.fulfilled, (state, action) => {
      state.isdeleteOpen = action.payload.isdeleteOpen;
      state.documentItem = action.payload.rowData;
    })
  },
});

export default physicianSlice.reducer;
