import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Modal, ModalBody, ModalHeader, Row, Spinner, Toast } from "reactstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ArrowUpward, Close } from "@mui/icons-material";
import pdf from '../../assets/images/pdf.svg';
import { Calendar } from "primereact/calendar";
import { createMrn, getSites, mrnModal, referralfetchAll } from "../../redux/referralsSlice";
import { Dropdown } from "primereact/dropdown";
import { getAddressInfo, getAllZipcode } from "../../redux/physicianSlice";
import { toast, ToastContainer } from 'react-toastify';
import { displayToast } from "../../components/Toast/Toast";
import Document from "./Document";

export default function MrnPatient({ userEmail }) {
    const dispatch = useDispatch();
    const { modalLoading, isMrnOpen, site, referralItem } = useSelector((state) => state.referral);
    const { zipItem } = useSelector((state) => state.physician);



    const [loading, setLoading] = useState(false);

    const mapToOptions = (data, labelKey, valueKey) => (
        data?.map(item => ({
            label: item[labelKey],
            value: item[valueKey]
        })) || []
    );

    const zipCodeOptions = mapToOptions(zipItem?.data, 'zipcode', 'zipcode_id');




    const [fileNames, setFileNames] = useState([]);
    const [fileUploadError, setFileUploadError] = useState("");
    const [value, setValue] = useState("");
    const [phonevalue, setPhoneValue] = useState("");

    const toggle = () => {
        dispatch(mrnModal({ isOpen: false }));
        setValue('');
        setPhoneValue('');
    };

    useEffect(() => {
        if (isMrnOpen) {
            dispatch(getSites());
            dispatch(getAllZipcode());
        }
    }, [isMrnOpen])

    const MAX_FILES = 2;
    const FILE_SIZE = 5_000_000;
    const FILE_SUPPORTED_FORMATS = ["pdf"];

    const validationSchema = Yup.object({
        name: Yup.string().required("First Name is required"),
        lname: Yup.string().required("Last Name is required"),
        site_no: Yup.string().required("Site is required"),
        date: Yup.date().required("Date of Birth is required").max(new Date(), "You can't be born in the future!"),
        // files: Yup.string().required("At least one file is required"),
        ssn: Yup.string().matches(/^\d{3}-\d{2}-\d{4}$/, { message: "Invalid SSN format. Use ###-##-#### format.", }),
        phone: Yup.string().matches(/^\d{3}-\d{3}-\d{4}$/, { message: "Invalid phone format. Use ###-###-#### format.", }),

    });

    const initialValues = {
        name: referralItem?.first_name || "",
        lname: referralItem?.last_name || "",
        date: referralItem?.date_of_birth ? new Date(referralItem.date_of_birth) : null,
        files: "",
        site_no: "",
        zip: referralItem?.zipcode_id || "",
        phone: "",
        ssn: ""
    };

    const onSubmit = async (values) => {
        const zipValue = values.zip; // This will be the zipcode_id
        const selectedZipCode = zipItem?.data?.find(item => item.zipcode_id === zipValue)?.zipcode || "";

        try {
            // setLoading(true);
            const mrnData = {
                firsT_NAME: values.name,
                lasT_NAME: values.lname,
                dob: values.date,
                phone: values.phone,
                ssn: "",
                siteno: values.site_no,
                referral_id: referralItem?.referral_id,
                zip: selectedZipCode,
                loginEmail: userEmail,
            }


            await dispatch(createMrn({
                mrnData, callback: (res) => {
                    if (res?.data?.success) {
                        displayToast({ msg: "Create New Patient Successfully", type: "success" })
                    }
                    else {
                        displayToast({ msg: res?.data?.message || "Something went wrong", type: "error" })
                    }
                }
            }));
            toggle();
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleFileUpload = (files, setFieldValue) => {
        const invalidFiles = files.filter(file => {
            const fileExtension = file.name.split('.').pop().toLowerCase();
            return file.size > FILE_SIZE || !FILE_SUPPORTED_FORMATS.includes(fileExtension);
        });

        if (invalidFiles.length > 0) {
            const sizeError = invalidFiles.some(file => file.size > FILE_SIZE);
            const typeError = invalidFiles.some(file => !FILE_SUPPORTED_FORMATS.includes(file.name.split('.').pop().toLowerCase()));
            if (sizeError && typeError) {
                setFileUploadError(`Files must be less than ${FILE_SIZE / 1_000_000} MB and in PDF format.`);
            } else if (sizeError) {
                setFileUploadError(`Each file must be less than ${FILE_SIZE / 1_000_000} MB.`);
            } else {
                setFileUploadError(`Only PDF files are allowed.`);
            }
            setFieldValue("files", "");
            setFileNames([]);
        } else {
            const newFileNames = files.map(file => file.name);
            setFileUploadError("");
            setFieldValue("files", newFileNames.join(","));
            setFileNames(prevFileNames => [...prevFileNames, ...newFileNames]);
        }
    };

    const renderError = (message) => <small className="help text-danger m-0">{message}</small>;

    const handleRemoveFile = (index, setFieldValue, values) => {
        const fileNamesArray = values.files.split(",");
        fileNamesArray.splice(index, 1);
        const updatedFiles = fileNamesArray.join(",");
        setFieldValue("files", updatedFiles);
        setFileNames(fileNamesArray);
    };

    const siteOptions = site?.map(item => ({
        label: item.site_name,
        value: item.site_no
    })) || [];

    const phoneNumberAutoFormat = (phoneNumber) => {
        const number = phoneNumber.replace(/\D/g, '');

        if (number.length <= 3) {
            return number;
        } else if (number.length <= 5) {
            return `${number.slice(0, 3)}-${number.slice(3)}`;
        } else {
            return `${number.slice(0, 3)}-${number.slice(3, 5)}-${number.slice(5, 9)}`;
        }
    };

    const phoneNumber = (value) => {
        // const cleaned = ('' + value).replace(/\D/g, '');
        // const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        // if (match) {
        //     return `${match[2]}-${match[2]}-${match[3]}`;
        // }
        // return value;
        const number = value?.replace(/\D/g, '');

        if (number.length <= 3) {
            return number;
        } else if (number.length <= 6) {
            return `${number.slice(0, 3)}-${number.slice(3)}`;
        } else {
            return `${number.slice(0, 3)}-${number.slice(3, 6)}-${number.slice(6, 10)}`;
        }
    };

    const onChangePhone = (e, setFieldValue) => {
        const targetValue = phoneNumber(e.target.value);
        setPhoneValue(targetValue);
        setFieldValue("phone", targetValue);
    };

    const onChange = (e, setFieldValue) => {
        const targetValue = phoneNumberAutoFormat(e.target.value);
        setValue(targetValue);
        setFieldValue("ssn", targetValue)
    };


    const getLocalOffsetInMinutes = () => {
        const localDate = new Date();
        const utcOffset = localDate.getTimezoneOffset();
        return -utcOffset;
    };

    const convertUTCToLocal = (utcDate) => {
        const date = new Date(utcDate);
        const localOffset = getLocalOffsetInMinutes();
        return new Date(date.getTime() + localOffset * 60 * 1000);
    };




    return (
        <>
            <Toast ref={toast} className="border-0" />

            <Modal className="referral-modal" isOpen={isMrnOpen} toggle={toggle} centered backdrop={"static"}>
                <ModalHeader className="w-100 align-items-start pb-2" toggle={toggle}>
                    Create New Patient
                </ModalHeader>
                <ModalBody>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={async (values, { resetForm }) => {
                            await onSubmit(values);
                            resetForm();
                            setFileNames([]);
                            setFileUploadError("");
                        }}
                        validateOnChange={true}
                        validateOnBlur={true}
                    >
                        {({ values, setFieldValue }) => (


                            < Form >
                                <div className="custom-form email-form physician-form">
                                    <Row className="field mb-2">
                                        <Col md="3">
                                            <label className="label" htmlFor="name">
                                                First Name
                                            </label>
                                        </Col>
                                        <Col md="9">
                                            <div className="control">
                                                <Field name="name" type="text" className="form-control" placeholder="Enter First Name" />
                                                <ErrorMessage name="name" render={renderError} />
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className="field mb-2">
                                        <Col md="3">
                                            <label className="label" htmlFor="lname">
                                                Last Name
                                            </label>
                                        </Col>
                                        <Col md="9">
                                            <div className="control">
                                                <Field name="lname" type="text" className="form-control" placeholder="Enter Last Name" />
                                                <ErrorMessage name="lname" render={renderError} />
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className="field mb-2">
                                        <Col md="3">
                                            <label className="label" htmlFor="phone">
                                                Phone
                                            </label>
                                        </Col>
                                        <Col md="9">
                                            <div className="control">
                                                <Field name="phone">
                                                    {({ field }) => (
                                                        <input
                                                            {...field}
                                                            type="text"
                                                            className="form-control"
                                                            value={phonevalue}
                                                            placeholder="XXX-XXX-XXXX"
                                                            maxLength={12}
                                                            onChange={(e) => onChangePhone(e, setFieldValue)}
                                                        />
                                                    )}
                                                </Field>
                                                <ErrorMessage name="phone" render={renderError} />
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className="field mb-2">
                                        <Col md="3">
                                            <label className="label" htmlFor="ssn">
                                                SSN
                                            </label>
                                        </Col>
                                        <Col md="9">
                                            <div className="control">
                                                <Field name="ssn">
                                                    {({ field }) => (
                                                        <input
                                                            {...field}
                                                            type="text"
                                                            className="form-control"
                                                            value={value}
                                                            placeholder="XXX-XX-XXXX"
                                                            maxLength={11}
                                                            onChange={(e) => onChange(e, setFieldValue)}
                                                        />
                                                    )}
                                                </Field>
                                                <ErrorMessage name="ssn" render={renderError} />
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className="field mb-2">
                                        <Col md="3">
                                            <label className="label" htmlFor="zip">
                                                Zip
                                            </label>
                                        </Col>
                                        <Col md="9">
                                            <div className="control">
                                                {/* <Field name="zip" type="text" className="form-control" placeholder="Enter Zip Code" /> */}
                                                <Dropdown
                                                    id="zip"
                                                    name="zip"
                                                    value={values.zip}
                                                    options={zipCodeOptions}
                                                    onChange={(e) => {
                                                        setFieldValue("zip", e.value);
                                                    }}
                                                    optionLabel="label"
                                                    placeholder="Select Zip Code"
                                                    filter
                                                    filterPlaceholder="Search Zip Code"
                                                    className="form-control"
                                                    virtualScrollerOptions={{ itemSize: 38 }}
                                                />
                                                {/* <ErrorMessage name="zip" render={renderError} /> */}
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className="field mb-2">
                                        <Col md="3">
                                            <label className="label" htmlFor="site">
                                                Site <span className="text-danger">*</span>
                                            </label>
                                        </Col>
                                        <Col md="9">
                                            <div className="control">

                                                <Dropdown
                                                    id="site_no"
                                                    name="site_no"
                                                    value={values.site_no}
                                                    options={siteOptions}
                                                    onChange={(e) => {
                                                        setFieldValue("site_no", e.value);
                                                    }}
                                                    className="form-control"
                                                    optionLabel="label"
                                                    placeholder="Select Site"
                                                    filter

                                                />
                                                <ErrorMessage name="site_no" render={renderError} />

                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className="field mb-2">
                                        <Col md="3">
                                            <label className="label" htmlFor="date">
                                                DOB
                                            </label>
                                        </Col>
                                        <Col md="9">
                                            <div className="control">
                                                <Calendar
                                                    name="date"
                                                    showIcon
                                                    value={values?.date}
                                                    placeholder="Enter DOB Date"
                                                    className="custom-calendar"
                                                    onChange={(event) => {
                                                        const selectedDate = convertUTCToLocal(event.value);
                                                        setFieldValue("date", selectedDate);


                                                    }}
                                                    maxDate={new Date()}
                                                />
                                                <ErrorMessage name="date" render={renderError} />
                                            </div>
                                        </Col>
                                    </Row>

                                    <Document documentItem={referralItem} />


                                    <div className="field mb-2">
                                        <div className="control">
                                            <div className="file-upload">

                                                <input
                                                    id="files"
                                                    name="files"
                                                    type="file"
                                                    multiple
                                                    className="custom-upload"
                                                    onChange={(event) => {
                                                        const newFiles = Array.from(event.currentTarget.files);
                                                        if (newFiles.length > MAX_FILES) {
                                                            setFileUploadError(`You can upload up to ${MAX_FILES} files`);
                                                        } else {
                                                            handleFileUpload(newFiles, setFieldValue);
                                                        }
                                                    }}
                                                />
                                                <label htmlFor="files" className="custom-upload-label">
                                                    <ArrowUpward /> Upload Files
                                                </label>
                                                {fileNames.length > 0 && (
                                                    <div className="file-names custom-file">
                                                        {fileNames.map((name, index) => (
                                                            <span key={index} className="file-name">
                                                                <img
                                                                    src={pdf}
                                                                    alt="pdf"
                                                                />
                                                                {name}
                                                                <Close
                                                                    onClick={() => handleRemoveFile(index, setFieldValue, values)}
                                                                    style={{ cursor: "pointer", marginLeft: "5px" }}
                                                                />
                                                            </span>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                            {fileUploadError && (
                                                <small className="help text-danger m-0">{fileUploadError}</small>
                                            )}
                                            <snap className="mt-2 d-inline-block">Only PDF allow</snap>
                                            {/* <ErrorMessage name="files" render={renderError} /> */}
                                        </div>
                                    </div>
                                    <div className="d-flex mt-2 border-top pt-3 mt-3 justify-content-end">
                                        <button type="button" className="custom-button me-2" onClick={toggle}>
                                            Cancel
                                        </button>
                                        <button type="submit" disabled={modalLoading} className="common-btn ">
                                            Create New MRN
                                            {modalLoading && <Spinner className="ms-2" size="sm" color="light" />}
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </ModalBody>
            </Modal >


        </>

    );
}