import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../utility/api";



export const getAssignRoleByUser = createAsyncThunk("getAssignRoleByUser ", async () => {
    try {
        const response = await api.get(`/api/ArManager/GetAssignRoleByUser`);

        return response.data;
    } catch (error) {
        console.error("Error fetching patient data:", error);
        throw error;
    }
});

const permissionSlice = createSlice({
    name: "permissions",
    initialState: {
        loading: false,
        assignRoleByUser: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAssignRoleByUser.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getAssignRoleByUser.fulfilled, (state, action) => {
            state.assignRoleByUser = action.payload.data
        });
        builder.addCase(getAssignRoleByUser.rejected, (state, action) => {
            state.loading = false;
        });

    },
});


export default permissionSlice.reducer;
